.wysiwyg {
    p, ul, ol {
        @apply mb-[1.5em];

        &:last-child {
            margin-bottom: 0;
        }
    }

    b, strong {
        @apply font-medium;
    }

    h2 {
        @apply font-medium text-[1.5em] mb-[1em];
    }

    h3 {
        @apply font-medium text-[1.25em] mb-[0.25em];
    }

    li {
        @apply flex mb-[0.5em] last:mb-0;
        &:before {
            @apply mr-[0.5em] font-medium;
        }
    }

    ul {
        @apply list-none;
        li {
            &:before {
                content: "-";
            }
        }
    }

    ol {
        @apply list-none;
        counter-reset: ol-counter;
        li {
            counter-increment: ol-counter;
            &:before {
                content: "#"counter(ol-counter);
            }
        }
    }
    .extra-line-height {
        line-height: 1.5;
    }
}
