@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */

    //.btn {
    //    @apply bg-blue m:bg-red;
    //}

    .default-link, .wysiwyg a {
        @apply underline decoration-current transition-colors duration-350 ease-out hover:decoration-transparent;
    }

    .inverted-link, .inverted-links a {
        @apply underline decoration-transparent transition-colors duration-350 ease-out hover:decoration-current;
    }

    .vertical-text {
        writing-mode: vertical-rl;
        text-orientation: mixed;
    }

    .image-zoom {
        img, video {
            position: absolute;
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            max-width: none;
            will-change: opacity, transform;

        }
        img.lazyloaded, video {
           transition: transform 0.75s theme('transitionTimingFunction.out-quad'), opacity 0.5s theme('transitionTimingFunction.out-cubic');
        }
    }
}
